import { FC } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/trescarabelas.png'

const Footer: FC = () => {
   return (
      <footer className="bg-primary-codGray">
         <div className="container py-20 text-center md:text-left md:flex animate animate-fade-in">
            <Link to="/" className="w-32 h-10">
               <img src={Logo} alt="Logo" className='w-full h-auto' />
            </Link>

            <div
               className="uppercase text-sm leading-7 font-normal text-white tracking-widest 
               md:ml-32 xl:ml-48 xl:flex">
               <div className="font-light mt-10 mb-8 md:mt-0 xl:mb-0 xl:mr-44">
                  <div>Punta Mita,<br />
                     Nayarit</div>
                  <div>+52 322 111 7595</div>
               </div>

               <div className='font-bold'>
                  Horarios<br />
                  <span className='font-light'>
                     LUN - VIE: 09:00 AM - 10:00 PM<br />
                     SAB - DOM: 09:00 AM - 11:30 PM<br />
                  </span>
               </div>
            </div>
         </div>
      </footer>
   )
}

export default Footer
